












































































































































import { Vue, Provide, Component } from "vue-property-decorator";
import { Toast } from "vant";
import area from "@/utils/area";
import Nav from "@/components/header.vue";
import serve from "@/serve/login";
import MD5 from "js-md5";
interface File {
  status: string;
  message: string;
}
@Component({
  components: {
    Nav,
  },
})
export default class Signin extends Vue {
  officePictures = [];
  businessLicense = "";
  fileList2 = [];
  areaId = "" || null;
  areaData=[]
  @Provide() mobile = "";
  @Provide() code = "";
  @Provide() sendLoading = false;
  @Provide() time = 60;
  @Provide() password = "";
  @Provide() newpassword = "";
  @Provide() show = false;
  @Provide() areaList = area; // 省市区数据
  @Provide() address = ""; // 区域
  @Provide() name = ""; // 公司名称
  @Provide() QQ = ""; // qq  agree
  @Provide() fileList = []; // 营业执照
  @Provide() agree = false; // 同意协议
  created(){
    this.getChina()
  }
  async getChina(){
    const res = await serve.getChina()
    this.areaData = res.data.data
  }
  async signInSubmit() {
    const data = {
      city: this.address,
      areaId: this.areaId,
      name: this.name,
      phone: this.mobile,
      code: this.code,
      level: 1,
      qq: this.QQ,
      password: MD5(this.password),
      officePictures: this.officePictures.toString(),
      businessLicense: this.businessLicense,
    };
    if (this.agree) {
      const res = await serve.register(data);
      if (res.data.code === 0) {
        this.$router.push("/signInSuccess");
        // Toast.success("注册成功");
      } else {
        Toast.fail(res.data.message);
      }
    }else {
      Toast('请先同意注册协议')
    }
  }
  delImg(): void {
    this.fileList = [];
  }
  onOversize(): void {
    Toast.fail("文件大小不能超过2MB");
  }
  async sendSms() {
    if (this.mobile) {
      const reg = /^1[3-9]\d{9}$/;
      if (reg.test(this.mobile)) {
        this.sendLoading = true;
        this.cutTime();
        const res = await serve.getCode(this.mobile);
        if (res.data.code === 0) {
          Toast.success("验证码已发送，请注意查收");
        } else {
          Toast.fail(res.data.message);
        }
      } else {
        Toast("手机号格式错误");
      }
    } else {
      Toast("请输入手机号");
    }
  }

  private phoneValidator(val: string): boolean {
    return /^1[3-9]\d{9}$/.test(val);
  }
  private validator(val: string): boolean {
    // 验证密码
    return val.length > 5 && val.length < 16;
  }

  private showPopup(): void {
    this.show = true;
  }

  private goBack(): void {
    this.$router.push("/login");
  }
  private define(val: object): void {
    this.address = val[2].name;
    this.areaId = this.areaData.map(item => {
      if(item.name === val[2].name){
        return item.id
      }
    });
    this.show = false;
  }
  private cutTime(): void {
    const timer = setInterval(() => {
      this.time--;
      if (this.time == 0) {
        clearInterval(timer);
        this.time = 60;
        this.sendLoading = false;
      }
    }, 1000);
  }

  async afterRead(file: File) {
    const fd = new FormData();
    fd.append("file", file["file"]);
    const res = await serve.upload(fd);
    if (res.data.code === 0) {
      this.businessLicense = res.data.data[0];
    }
  }
  async afterRead2(file: File) {
    const fd = new FormData();
    fd.append("file", file["file"]);
    const res = await serve.upload(fd);
    if (res.data.code === 0) {
      this.officePictures.push(res.data.data[0]);
    }
  }
}
